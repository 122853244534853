/* You can add global styles to this file, and also import other style files */

html, body { height: 100%;
             margin: 0;
             padding: 0;
             }
body {
    // font-family: Roboto, "Helvetica Neue", sans-serif;
    overflow-x: hidden;

}
.cdk-overlay-container {
  z-index: 10000 !important;
}
.custom-z-index {
  z-index: 10000 !important;
}

.hover-zoom{
    transition: transform 0.3s ease;
}
.hover-zoom:hover {
    transform: scale(1.02);
}
*::-webkit-scrollbar {
    width: 12px;
  }

*::-webkit-scrollbar-track {
    background: #ffffff;
  }

*::-webkit-scrollbar-thumb {
    background-color: #b5b5b5;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
*::-webkit-scrollbar-thumb:hover {
    background-color: #969696;
}
div.mat-expansion-panel-body {
  padding: 0 !important;
}
mat-nav-list{
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.btn-primary{
  background: linear-gradient(135deg, #007bff, #0056b3);
}
body.modal-open .main-content {
  filter: blur(5px); /* Applique un flou de 5px */
  transition: filter 0.3s ease; /* Animation fluide */
}

/* Style du backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.295); /* Couleur sombre semi-transparente */
  z-index: 1050;
  display: flex;
}

/* You can add other styles here */
